<!-- 发布记录列表 -->
<template>
  <Layout>
    <div class="back-view">
      <div
        style="
          justify-content: center;
          align-items: center;
          display: flex;
          flex-direction: column;
          padding-left: 20px;
        "
      >
        <div class="top-view">
          <el-select
            @change="activityBigTypeChange()"
            v-model="activityType"
            placeholder="接龙类型"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="sortType"
            placeholder="发布时间"
            clearable
            style="margin-left: 10px"
            @change="sortTypeChange()"
          >
            <el-option
              v-for="item in timeoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="list-back-view">
        <el-table border :data="tableData" style="width: 1080px">
          <el-table-column type="index" label="序号" width="50" align="center">
          </el-table-column>
          <el-table-column
            prop="name"
            label="接龙名称"
            width="180"
            align="center"
          >
            <template slot-scope="scope">
              <div style="display: flex">
                <div v-if="recordType != 4">
                  <div
                    :class="{
                      nobegin: scope.row.active_state == 1,
                      alreadyend: scope.row.active_state == 2,
                      going: scope.row.active_state == 3,
                    }"
                    v-if="scope.row.is_draft == 0"
                  >
                    <p v-if="scope.row.active_state == 1">未开始</p>
                    <p v-if="scope.row.active_state == 2">已结束</p>
                    <p v-if="scope.row.active_state == 3">进行中</p>
                  </div>
                  <div
                    class="cgclass"
                    v-if="scope.row.is_draft == 1 && scope.row.is_draft"
                  >
                    草稿
                  </div>
                </div>
                <div
                  :class="{
                    nobegin: scope.row.active_state == 1,
                    alreadyend: scope.row.active_state == 2,
                    going: scope.row.active_state == 3,
                  }"
                  v-if="recordType == 4"
                >
                  <div v-if="scope.row.active_state == 1">未开始</div>
                  <div v-if="scope.row.active_state == 2">已结束</div>
                  <div v-if="scope.row.active_state == 3">进行中</div>
                </div>

                <div>
                  {{ scope.row.name }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="activity_type_name"
            label="接龙类型"
            width="80"
            align="center"
          >
            <template slot-scope="scope">
              <div class="jlclass" v-show="scope.row.activity_type == 106">
                接龙
              </div>
              <div class="dkclass" v-show="scope.row.activity_type != 106">
                打卡
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="add_time"
            label="发布时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="browse_num"
            label="访问量"
            align="center"
            width="177"
          ></el-table-column>
          <el-table-column
            prop="sign_num"
            label="参与人数"
            align="center"
            width="177"
          >
            <template slot-scope="scope">
              <span
                style="
                  text-decoration: underline;
                  color: #3e7bfa;
                  cursor: pointer;
                "
                @click="joinClick(scope.row)"
                >{{ scope.row.sign_num }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="177"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                icon="el-icon-share"
                style="margin-right: 10px; color: #019688; font-size: 14px"
                type="text"
                size="small"
                @click="shareClick(scope.row)"
                >分享{{ scope.index }}</el-button
              >
              <el-dropdown trigger="click">
                <el-button
                  type="text"
                  icon="el-icon-setting"
                  style="color: #019688; font-size: 14px"
                  class="el-dropdown-link"
                >
                  管理
                </el-button>

                <el-dropdown-menu slot="dropdown">
                  <!-- <p v-if="scope.row.active_state == 1">未开始</p>
                  <p v-if="scope.row.active_state == 2">已结束</p>
                  <p v-if="scope.row.active_state == 3">进行中</p> -->
                  <el-dropdown-item
                    icon="el-icon-setting"
                    @click.native="dropdownSet(scope.row)"
                    >编辑修改</el-dropdown-item
                  >

                  <el-dropdown-item
                    v-show="
                      scope.row.active_state == 1 ||
                      scope.row.active_state == 2 ||
                      scope.row.active_state == 3
                    "
                    icon="el-icon-switch-button"
                    @click.native="dropdownEnd(scope.row)"
                    >{{
                      scope.row.active_state == 1
                        ? "立即开始"
                        : scope.row.active_state == 2
                        ? "重启活动"
                        : scope.row.active_state == 3
                        ? "提前结束"
                        : ""
                    }}</el-dropdown-item
                  >

                  <el-dropdown-item
                    icon="el-icon-notebook-2"
                    @click.native="dropdownList(scope.row)"
                    >报名列表</el-dropdown-item
                  >
                  <el-dropdown-item
                    icon="el-icon-document-copy"
                    @click.native="dropdownCopy(scope.row)"
                    >复制</el-dropdown-item
                  >
                  <el-dropdown-item
                    icon="el-icon-delete"
                    @click.native="dropdownDel(scope.row)"
                    >删除</el-dropdown-item
                  >
                  <!-- <el-dropdown-item icon="el-icon-circle-check"
                    >收藏</el-dropdown-item
                  > -->
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        style="text-align: center; margin-top: 20px"
        v-show="tablePage.total"
      >
        <el-pagination
          background
          layout="sizes,prev, pager, next,total"
          :page-sizes="[20, 50, 100, 200]"
          :total="tablePage.total"
          :current-page="tablePage.pageNum"
          :page-size="tablePage.pageSize"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog title="分享" :visible.sync="dialogVisible" width="600px" center>
      <div
        style="
          text-align: center;
          background-color: #f6f7fa;
          padding-top: 30px;
          padding-bottom: 30px;
        "
      >
        <div class="radioBack">
          <el-radio-group v-model="shareChange" fill="#409eff">
            <el-radio-button
              border="false"
              label="下载接龙码"
            ></el-radio-button>
            <el-radio-button
              border="false"
              label="嵌入公众号/文章"
            ></el-radio-button>
          </el-radio-group>
        </div>

        <div v-if="shareChange == '下载接龙码'">
          <div>
            <el-image :src="codeUrl" class="code-image"></el-image>
          </div>

          <el-button
            @click="downLoadCodePic(codeUrl)"
            type="primary"
            round
            style="margin-top: 40px"
            >下载二维码</el-button
          >
        </div>
        <div
          v-else
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
        >
          <div
            style="
              width: 330px;
              height: 85px;
              background-color: white;
              border-radius: 5px;
              margin: 10px;
              padding: 10px;
              margin-left: 10px;
              margin-top: 20px;
            "
          >
            <div style="display: flex">
              <span
                style="
                  color: #474747;
                  font-weight: 300;
                  width: 83px;
                  text-align: left;
                "
                >小程序ID:</span
              >
              <span
                style="
                  color: #010100;
                  font-weight: 400;
                  width: 200px;
                  text-align: left;
                "
                >{{ appid }}</span
              >
            </div>
            <div
              style="
                display: flex;
                justify-content: space-between;
                margin-left: 0px;
                margin-top: 15px;
              "
            >
              <div>
                <span style="color: red; line-height: 28px; text-align: left"
                  >*</span
                >
                <span
                  style="
                    color: #409eff;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 28px;
                    text-align: left;
                  "
                  >关联小程序时使用</span
                >
              </div>
              <el-button
                size="mini"
                round
                type="primary"
                @click="copyClick(appid)"
              >
                复制
              </el-button>
            </div>
          </div>

          <div
            style="
              width: 330px;
              height: 85px;
              background-color: white;
              border-radius: 5px;
              margin: 10px;
              padding: 10px;
              margin-left: 10px;
            "
          >
            <div style="display: flex">
              <span
                style="
                  color: #474747;
                  font-weight: 300;
                  width: 83px;
                  text-align: left;
                "
                >页面路径:</span
              >
              <span
                style="
                  color: #010100;
                  font-weight: 400;
                  width: 200px;
                  text-align: left;
                "
                >{{ faceUrl }}</span
              >
            </div>
            <div
              style="
                display: flex;
                justify-content: space-between;
                margin-left: 0px;
                margin-top: 15px;
              "
            >
              <div>
                <span style="color: red; line-height: 28px; text-align: left"
                  >*</span
                >
                <span
                  style="
                    color: #409eff;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 28px;
                    text-align: left;
                  "
                  >嵌入菜单，文章，回复时使用</span
                >
              </div>
              <el-button
                size="mini"
                round
                type="primary"
                @click="copyClick(faceUrl)"
              >
                复制
              </el-button>
            </div>
          </div>
          <div
            style="
              width: 330px;
              background-color: white;
              border-radius: 5px;
              margin: 10px;
              padding: 10px;
              margin-left: 10px;
            "
          >
            <div
              style="
                text-align: center;
                font-size: 20px;
                font-weight: 500;
                margin-top: 10px;
              "
            >
              使用教程
            </div>

            <div style="margin-top: 10px; margin-bottom: 10px">
              <button
                style="cursor: pointer"
                :class="isAcive == '嵌入到公众号' ? 'acStyle' : 'defStyle'"
                @click="clickHandler"
              >
                嵌入到公众号
              </button>
              <button
                style="cursor: pointer"
                :class="isAcive == '嵌入到文章' ? 'acStyle' : 'defStyle'"
                @click="clickHandler"
              >
                嵌入到文章
              </button>
              <!-- kEu47kwFr2oOr9rO/modularImg/1707035652084.jpg -->
            </div>
            <div v-show="isAcive == '嵌入到公众号'">
              <el-image
                style="width: 300px"
                :src="require('@/assets/images/qrgzh.jpg')"
              >
              </el-image>
            </div>
            <div v-show="isAcive == '嵌入到文章'">
              <el-image
                style="width: 300px"
                :src="
                  imageUrl + 'kEu47kwFr2oOr9rO/modularImg/1707035652084.jpg'
                "
              >
              </el-image>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </Layout>
</template>
  <script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
// import router from "../../../router/index";
import {
  getCreateList,
  getActivityCode,
  earlyTermination,
  restartActivity,
  startImmediately,
  delActivity,
} from "@/utils/api/activity";
import { imageUrl } from "@/utils/config/commonUrl.js";

/**
 * Basic-table component
 */
export default {
  page: {
    title: "发布记录",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout },
  data() {
    return {
      imageUrl: imageUrl,
      codeUrl: "",
      options: [
        { label: "全部", value: "" },
        { label: "接龙", value: 106 },
        { label: "打卡", value: 109 },
      ],

      timeoptions: [
        { label: "由近及远", value: 1 },
        { label: "由远及近", value: 2 },
      ],

      tableData: [],
      dialogVisible: false,
      shareChange: "下载接龙码",
      appid: "wx61879a43e8f11e76",

      faceUrl: "/page/index/index",
      recordType: 1, //1我的发布   4 我的参加

      isAcive: "嵌入到公众号",

      activityType: "", //106 接龙和 109打卡类型

      sortType: 1, //1、倒序 2、正序

      tablePage: {
        pageNum: 1, //
        pageSize: 20,
        total: 0,
      },
    };
  },
  created() {
    this.getCreateList();

    // console.log(localStorage.getItem("userID"),"用户ID");
  },
  methods: {
    handleSizeChange(pageSize) {
      this.tablePage.pageNum = 1;
      this.tablePage.pageSize = pageSize;
      this.getCreateList();
    },
    handlePageChange(currentPage) {
      this.tablePage.pageNum = currentPage;
      this.getCreateList();
    },
    //切换类型
    activityBigTypeChange() {
      this.getCreateList();
    },
    // 切换时间排序
    sortTypeChange() {
      this.getCreateList();
    },
    getCreateList() {
      // 签到打卡
      getCreateList({
        pageSize: this.tablePage.pageSize,
        pageNum: this.tablePage.pageNum,
        activityBigType: 1,
        recordType: this.recordType,
        sortType: this.sortType,
        activityType: this.activityType,
      }).then((res) => {
        // console.log(res, "请求列表数据");
        if (res.status == 1) {
          this.tableData = res.data.list;
          this.tablePage.total = res.data.total;
        }
      });
    },

    //展示分享弹窗
    shareClick(row) {
      console.log(row, "row");

      // 获取二维码
      this.getActivityCode(row);
      this.dialogVisible = true;
    },

    // 获取二维码
    getActivityCode(row) {
      let scene = "id=" + row.id + "&st=see&ity=" + row.activity_type;
      getActivityCode({
        activityId: row.id,
        page: "pages-mine/solataireGroupDetails/solataireGroupDetails",
        scene: scene,
      }).then((res) => {
        console.log(res, "获取二维码");

        if (res.status == 1) {
          this.codeUrl = this.imageUrl + res.data.smallCode;
          console.log(this.codeUrl, "this.codeUrl");
        }
      });
    },

    downLoadCodePic(url) {
      this.downloadIamge(url, new Date().valueOf());
    },

    //
    downloadIamge(imgsrc, name) {
      // 下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "Anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.fillStyle = "#FFFFFF";
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); // 得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },

    // 编辑修改
    dropdownSet(row) {
      console.log(row, "去编辑--内容");
      console.log(row.createId != row.user_id);
      console.log(row.is_admin == 2);
      if ((row.createId && row.createId != row.user_id) || row.is_admin == 2) {
        this.$message("没有权限修改");
        return false;
      }
      this.$router.push({
        path: "/solitaire",
        query: {
          activityId: row.id,
          selectType: "update",
          isType: row.activity_type,
        },
      });
    },

    // 提前结束
    dropdownEnd(row) {
      var toastMessage = "";
      if (row.active_state == 1) {
        // 活动未开始
        toastMessage = "是否立即开始该活动";
      } else if (row.active_state == 2) {
        // 活动已结束
        toastMessage = "是否立即重启该活动";
      } else if (row.active_state == 3) {
        // 活动进行中
        toastMessage = "是否提前结束该活动";
      }

      this.$confirm(toastMessage, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (row.active_state == 1) {
            // 活动未开始
            startImmediately({
              id: row.id,
            }).then((res) => {
              console.log(res, "立即开始接口");
              this.$message({
                type: "success",
                message: "成功!",
              });
              this.getCreateList();
            });
            // earlyTermination,restartActivity,startImmediately
          } else if (row.active_state == 2) {
            // 活动已结束
            restartActivity({
              id: row.id,
            }).then((res) => {
              console.log(res, "重启活动接口");
              this.$message({
                type: "success",
                message: "成功!",
              });
              this.getCreateList();
            });
          } else if (row.active_state == 3) {
            // 活动进行中
            console.log("提前结束接口");
            earlyTermination({
              id: row.id,
              is_it_over: 2,
              userId: localStorage.getItem("userID"),
            }).then((res) => {
              console.log(res, "提前结束接口");
              this.$message({
                type: "success",
                message: "成功!",
              });
              this.getCreateList();
            });
          }
        })
        .catch(() => {});

      console.log(row, "row");
    },

    // 报名列表
    dropdownList(row) {
      console.log(row, "row");
      // 跳转接龙参与列表
      // router.push("/joinList");

      this.$router.push({
        path: "/joinList",
        query: { activityId: row.id },
      });
    },
    // 复制
    dropdownCopy(row) {
      console.log(row, "row");
      this.$router.push({
        path: "/solitaire",
        query: {
          activityId: row.id,
          selectType: "add",
          isType: row.activity_type,
          copy: true,
        },
      });
    },
    // 删除
    dropdownDel(row) {
      console.log(row, "row");

      if (row.active_state == 2) {
        this.$confirm("确定删除该活动吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            delActivity({
              activityId: row.id,
            }).then((res) => {
              console.log(res, "删除接口");
              this.$message({
                type: "success",
                message: "成功!",
              });
              this.getCreateList();
            });
          })
          .catch(() => {});
      } else {
        this.$message({
          message: "活动未结束，不可以删除",
          type: "warning",
        });
      }
    },

    copyClick(sender) {
      var input = document.createElement("input"); // 创建input对象
      input.value = sender; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功！");
    },

    joinClick(sender) {
      console.log(sender);
      // 跳转接龙参与列表
      // router.push("/joinList");
      this.$router.push({
        path: "/joinList",
        query: { activityId: sender.id },
      });
    },

    clickHandler(event) {
      this.isAcive = event.target.innerText;
    },
  },
};
</script>
  <style lang="scss" scoped>
.back-view {
  background-color: #ffffff;
  width: 100%;
  min-height: 500px;
  // justify-content: center;
  // align-items: center;
  // display: flex;
  // flex-direction: column;
}

.top-view {
  height: 81px;
  display: flex;
  padding-top: 31px;
  // padding-left: 18px;

  width: 100%;
  max-width: 1100px;
}
.list-back-view {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  // min-height: 800px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 10px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}

.radioBack .el-radio-group {
  border-radius: 40px;
  overflow: hidden;
  border: 1px solid #409eff;
}
.radioBack .el-radio-button {
  // width: 120px;
  margin: 0;
}
.radioBack .el-radio-button__inner {
  color: #409eff;
  border: 0px solid #409eff;
  font-size: 12px;
}

.code-image {
  margin-top: 40px;
  width: 200px;
  height: 200px;
}
.acStyle {
  width: 120px;
  height: 30px;
  background-color: #4a73f8;
  color: white;
  font-size: 12px;
  border: 0;
}

.defStyle {
  width: 120px;
  height: 30px;
  background-color: white;
  color: #4a73f8;
  font-size: 12px;
  border: 1px solid #4a73f8;
}

.nobegin {
  margin-top: 2px;
  margin-right: 5px;
  margin-left: 15px;
  font-size: 12px;
  line-height: 19px;
  width: 45px;
  height: 19px;
  background: #fff0f0;
  border: 1px solid #feeaea;
  border-radius: 4px;
  color: #f67a7a;
}
.alreadyend {
  margin-top: 2px;
  margin-right: 5px;
  margin-left: 15px;
  font-size: 12px;
  line-height: 19px;
  width: 45px;
  height: 19px;
  background: #d8d8d8;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  color: white;
}
.going {
  margin-top: 2px;
  margin-right: 5px;
  margin-left: 15px;
  font-size: 12px;
  line-height: 19px;
  width: 45px;
  height: 19px;
  background: #1bce4a;
  border: 1px solid #1bce4a;
  border-radius: 4px;
  color: white;
}

.cgclass {
  margin-top: 2px;
  margin-right: 5px;
  margin-left: 15px;
  font-size: 12px;
  line-height: 19px;
  width: 45px;
  height: 19px;
  background: #fea951;
  border: 1px solid #fea951;
  border-radius: 4px;
  color: white;
}

.jlclass {
  width: 54px;
  height: 30px;
  background: #fff0f0;
  border: 1px solid #feeaea;
  border-radius: 4px;
  color: #f67a7a;
}

.dkclass {
  width: 54px;
  height: 30px;
  background: rgba(27, 206, 74, 0.16);
  border: 1px solid rgba(27, 206, 74, 0.11);
  border-radius: 4px;
  color: #1bce4a;
}
</style>